<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <div
      v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
      style="width:100%; height: 220px;overflow: hidden;"
    >
      <StreamBarcodeReader
        style="width:100%; height: 220px;"
        @decode="onDecode"
      />
    </div>
    <v-container class="pt-0">
      <v-row class="ma-0 pa-0">
        <v-col
          class="pa-0"
          cols="7"
        >
          <v-dialog
            ref="packingDialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="packingDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="packingDate"
                style="width:180px;"
                label="패킹기준일"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                autocomplete="off"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="packingDate"
              locale="ko-ko"
              @input="onpackingDateInput"
            />
          </v-dialog>
        </v-col>
        <v-spacer />
        <v-col
          class="mt-0 pb-0 pr-0"
          cols="5"
          align="right"
        >
          <v-btn
            depressed
            color="primary"
            @click="onClickpaickingEnd"
          >
            출고완료
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0  mb-0 ma-0 pa-0">
        <v-col
          class="mt-0  mb-0 ma-0 pa-0"
          cols="6"
        >
          <v-text-field
            id="input-invoice"
            v-model="invoiceNo"
            label="송장번호"
            placeholder="바코드 스캔"
            autocomplete="off"
            @keyup.enter.prevent="onEnterInvoiceNo"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-7 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
        <v-spacer />
        <v-col
          cols="5"
          align="right"
          class="mt-0  mb-0 ma-0 pa-0 pt-4"
        >
          <v-btn
            depressed
            color="success"
            @click="onClickAllSelect"
          >
            전체선택
          </v-btn>
        </v-col>
      </v-row>
      <dx-data-grid
        ref="refPackingDetailGrid"
        :data-source="packingInfoList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="noWbl"
        :focused-row-key.sync="focusedRowKeyPicking"
        @focused-row-changed="onSelectionChangedDetail"
        @toolbar-preparing="onToolbarPreparingDetail"
        @rowDblClick="OnRowDblClick"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxFilterRow
          :visible="false"
          apply-filter="auto"
        />
        <DxColumnFixing :enabled="false" />
        <DxColumn
          width="50"
          :buttons="editButtons"
          type="buttons"
        />
        <DxColumn
          :allow-editing="false"
          caption="송장번호"
          data-field="noWbl"
          sort-order="desc"
          :min-width="140"
        />
        <DxColumn
          :allow-editing="false"
          caption="오더번호"
          data-field="noOrd"
          :min-width="90"
        />
        <DxColumn
          :allow-editing="false"
          caption="출고"
          data-field="임시확인"
          :min-width="60"
        />
        <DxColumn
          :allow-editing="false"
          caption="검수"
          data-type="boolean"
          data-field="검수여부"
          :min-width="60"
        />
        <DxColumn
          :allow-editing="false"
          caption="판매번호"
          data-field="noSales"
          :min-width="80"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            column="noWbl"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtual"
          show-scrollbar="always"
          :use-native="true"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
    <div
      class="left-draw-wrap"
    >
      <v-app-bar
        id="left-draw-app-bar"
        dark
        height="50px"
      >
        <v-icon
          large
          @click="onClickBefore"
        >
          mdi-chevron-left
        </v-icon>
        <v-toolbar-title style="margin-left:10px;">
          택배 상세정보
        </v-toolbar-title>
      </v-app-bar>
      <div
        v-if="selectedRowDetail"
        class="left-draw-content"
      >
        <v-container>
          <div class="content-input-wrap">
            <div
              v-if="$store.getters.getMobileModel !== 'PDA' && showQrScanerByPbarcode==='block' && selectedRowDetail"
              style="width:100%; height: 220px;overflow: hidden;"
            >
              <StreamBarcodeReader
                style="width:100%; height: 220px;"
                @decode="onDecodeBarcode"
              />
            </div>
            <v-text-field
              v-model="selectedRowDetail.noWbl"
              label="운송장번호"
              :readonly="true"
              filled
              autocomplete="off"
            />
            <v-text-field
              v-model="selectedRowDetail.noOrd"
              label="오더No"
              :readonly="true"
              filled
              autocomplete="off"
            />
            <v-text-field
              id="input-barcode"
              v-model="barcode"
              label="바코드"
              placeholder="바코드 스캔"
              autocomplete="off"
              style=""
              @keyup.enter.prevent="onEnterBarcdoe"
            >
              <template v-slot:append>
                <v-icon
                  @click="showQrScanerByPbarcode = showQrScanerByPbarcode === 'none' ? 'block' : 'none'"
                >
                  mdi-camera
                </v-icon>
              </template>
            </v-text-field>
            <v-btn
              width="100%"
              color="primary"
              @click="onClickAllInspection"
            >
              일괄검수
            </v-btn>
          </div>
          <div style="margin-top:10px; background-color:black;">
            <dx-data-grid
              ref="refGoodsInputGrid"
              :data-source="goodsInputListInfo"
              :show-borders="true"
              :show-row-lines="false"
              :show-column-lines="true"
              :hover-state-enabled="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :allow-column-reordering="true"
              :row-alternation-enabled="true"
              :height="gridHeightSub"
              :focused-row-enabled="true"
              :auto-navigate-to-focused-row="false"
              key-expr="cdItem"
              :focused-row-key.sync="focusedRowKeyPcode"
              @row-updated="onRowUpdated"
              @row-updating="onRowUpdating"
            >
              <DxEditing
                :allow-deleting="false"
                :allow-updating="true"
                :allow-adding="false"
                :use-icons="true"
                mode="cell"
              />
              <DxColumn
                :allow-editing="false"
                caption="품명"
                data-field="품명"
                :min-width="250"
              />
              <DxColumn
                :allow-editing="false"
                caption="수량"
                data-field="qtPack"
                format="#,##0"
                :min-width="50"
              />
              <DxColumn
                :allow-editing="true"
                caption="검수"
                data-field="검수수량"
                format="#,##0"
                :min-width="50"
              />
              <DxColumn
                :allow-editing="false"
                caption="품번"
                data-field="cdItem"
                sort-order="desc"
                :min-width="120"
              />
              <dx-paging :enabled="false" />
              <dx-scrolling
                mode="virtual"
                show-scrollbar="always"
                :use-native="true"
              />
              <dx-sorting mode="multiple" />
              <loading
                :active.sync="isLoadingSlidePage"
                color="rgb(2, 110, 156)"
                loader="bars"
              />
            </dx-data-grid>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import DxDataGrid, {
  DxScrolling, DxTotalItem, DxSearchPanel, DxFilterRow, DxColumnFixing,
  DxSummary, DxColumn, DxPaging, DxSorting, DxEditing
} from 'devextreme-vue/data-grid'
import HttpService from '../../share/service/HttpService'
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  name: 'EmptView',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxColumnFixing,
    DxFilterRow,
    DxEditing,
    StreamBarcodeReader
  },
  props: {
    pickingOption: {
      type: String,
      default: 'POST'
    }
  },
  data: function () {
    return {
      showQrScaner: 'none',
      showQrScanerByPbarcode: 'none',
      isBarcodeScaned: false,
      pikingDate: null,
      isLoading: false,
      isLoadingSlidePage: false,
      userInfo: null,
      htmlWdth: 800,
      modal: false,
      datePickerWidth: '350px',
      gridHeight: null,
      selectedInType: {},
      inTypes: [],
      packingInfoList: null,
      focusedRowKeyPicking: null,
      focusedRowKeyPcode: null,
      selectedRowDetail: null,
      editButtons: [
        {
          hint: '출고취소',
          icon: 'refresh',
          visible: 'true',
          onClick: this.onClickDelete
        }],
      // 송장번호
      invoiceNo: null,
      goodsInputListInfo: null,
      gridHeightSub: null,
      barcode: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.packingDate = AppLib.getToday()
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 176)
    this.gridHeightSub = this.$Q('html').height() - 310
    this.htmlWdth = this.$Q('html').width()
    window.addEventListener('resize', this.handleResize)
    this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
    this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
    this.initInvoiceNo()
    this.refreshPackingList()
  },
  updated () {
  },
  methods: {
    // barcide acsn
    onDecode (barCode) {
      console.log(barCode)
      if (this.isBarcodeScaned) return
      this.invoiceNo = barCode
      this.onEnterInvoiceNo()
    },
    handleResize (event) {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)
      this.gridHeight = (this.$Q('html').height() - 176)
      this.gridHeightSub = this.$Q('html').height() - 310
    },
    initInvoiceNo () {
      this.isBarcodeScaned = false
      this.invoiceNo = null
      this.$Q('#input-invoice').focus()
    },
    onClickAllSelect () {
      if (this.packingInfoList.length === 0) return
      for (let i = 0; i < this.packingInfoList.length; i++) {
        this.packingInfoList[i].임시확인 = true
      }
      this.$snotify.info('이상 없을시 출고 완료하여 주세요.')
    },
    onClickpaickingEnd (e) {
      let where = this.$_.findWhere(this.packingInfoList, { 임시확인: false })
      if (where !== undefined) {
        this.$snotify.info('모든 패킹에 대해 출고 확인을 하여 주세요')
        return
      }
      where = this.$_.findWhere(this.packingInfoList, { 출고확인: false })
      if (where === undefined) {
        this.$snotify.info('해당 일자는 이미 출고 완료 되었습니다.')
        return
      }
      where = this.$_.findWhere(this.packingInfoList, { 검수여부: false })
      let confirmMsg = '출고 처리가 완료되면 수정을 할 수 없습니다.'
      if (where === undefined) {
        confirmMsg = '검수가 끝나지 않은 송장이 있습니다. 강제 출고 하시겠습니까?'
      }
      this.$snotify.confirm(confirmMsg, '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '출고',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              this.allUpdatePackingList()
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    OnRowDblClick (e) {
      console.log(e)
      this.selectedRow = e.data
      this.refreshDetailList()
    },
    allUpdatePackingList () {
      this.isLoading = true
      this.$_sp.runUpdateSqlProc('패킹리스트', ['dtPack'], null, [{ dtPack: this.packingDate, 출고확인: true }])
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('출고 처리 되었습니다.')
          HttpService.reqPost(null, '/updatePostPacking')
          this.refreshPackingList()
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onClickDelete (e) {
      if (e.row.data.출고확인) {
        this.$snotify.info('이미 배송이 완료 되어 초기화할 수 없습니다.')
        return
      }
      if (e.row.data.임시확인 === false) {
        this.$snotify.info('출고 확인된 항목이 아닙니다.')
        return
      }
      this.$snotify.confirm('출고 확인을 초기화 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '초기화',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              e.row.data.임시확인 = false
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    onEnterInvoiceNo (e) {
      console.log(this.invoiceNo)
      const where = this.$_.findWhere(this.packingInfoList, { noWbl: this.invoiceNo })
      if (where === undefined) {
        this.$snotify.info('해당 송장번호를 검색할 수 없습니다.')
        this.initInvoiceNo()
        return
      }
      // where.임시확인 = true
      this.focusedRowKeyPicking = this.invoiceNo
      this.initInvoiceNo()
      // this.$refs.refPackingDetailGrid.instance.refresh()
    },
    onSelectionChangedDetail (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRowDetail = e.row.data
        this.refreshDetailList()
      }
    },
    onToolbarPreparingDetail (e) {
    },
    onpackingDateInput () {
      this.$refs.packingDialog.save(this.packingDate)
      this.refreshPackingList()
    },
    refreshPackingList () {
      this.isLoading = true
      const param = {
        date: this.packingDate,
        option: 'POST'
      }

      this.$_sp.runNoEncodeFindProc('spFindAllPackingGbyNoByDate', param)
        .then((data) => {
          this.isLoading = false
          this.packingInfoList = this.$_sp.MakeModel(data)
          console.log(this.packingInfoList)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // left draw view
    // left slide view
    refreshDetailList () {
      // this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllPackingByInvoiceNo', { 송장번호: this.selectedRowDetail.noWbl })
        .then((data) => {
          this.isLoading = false
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
          console.log(this.goodsInputListInfo)
          const vm = this
          this.$Q('.left-draw-wrap').animate({
            left: '0'
          }, 800, function () {
            vm.$Q('#left-draw-app-bar').css('position', 'fixed')
            vm.$Q('.left-draw-content').css('padding-top', '50px')
            vm.$Q('#input-barcode').focus()
            // vm.focusedRowKeyOrder = null
          })
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onClickBefore () {
      this.focusedRowKeyPicking = null
      this.showQrScanerByPbarcode = 'none'
      this.$Q('#left-draw-app-bar').css('position', 'static')
      this.$Q('.left-draw-content').css('padding-top', '0px')
      this.$Q('.left-draw-wrap').animate({
        left: `${this.htmlWdth}px`
      }, 500)
      this.refreshPackingList()
    },
    onSelectionChanged (e) {
      /*
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
      */
    },
    onEnterBarcdoe (e) {
      this.checkBarcode(e.target._value)
    },
    onDecodeBarcode (barCode) {
      this.checkBarcode(barCode)
    },
    checkBarcode (barcode) {
      const isFoundBarcode = this.goodsInputListInfo.some(element => {
        if (element.바코드 !== null) {
          console.log(element.바코드)
          const isIncludesBarcode = element.바코드.split(',').includes(barcode)
          if (isIncludesBarcode) {
            this.focusedRowKeyPcode = element.cdItem
            this.regInspection(element)
            return true // 루프를 멈추기 위해 true 반환
          }
        }
        return false // 루프를 계속 진행하기 위해 false 반환
      })
      if (!isFoundBarcode) {
        // 알림 표시
        this.$snotify.error('일치하는 바코드가 존재하지 않습니다.')
      }
      this.barcode = null
    },
    regInspection (row) {
      if (row.검수수량 > row.qtPack) {
        this.$snotify.error('요청수량보다 검수수량이 많습니다.')
        return
      }
      this.isLoadingSlidePage = true
      const param = {
        noSales: row.noSales,
        cdItem: row.cdItem,
        noWbl: row.noWbl,
        검수수량: row.검수수량 + 1
      }
      this.$_sp.runUpdateSqlProc('패킹리스트', ['noSales', 'cdItem', 'noWbl'], null, [param])
        .then((result) => {
          row.검수수량 = row.검수수량 + 1
          this.checkAllInspection()
        }).catch((err) => {
          console.log(err)
        }).finally((e) => {
          this.$refs.refGoodsInputGrid.instance.refresh()
          this.isLoadingSlidePage = false
        })
    },

    onClickAllInspection () {
      this.$snotify.confirm('일괄 검수 하시겠습니까?', '', {
        timeout: 10000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {
            text: '검수',
            action: (toast) => {
              this.$snotify.remove(toast.id)
              const isSumEqual = this.goodsInputListInfo.every(item => item.qtPack === item.검수수량)
              if (isSumEqual) {
                this.$snotify.error('검수 완료 상태입니다.')
                return
              }

              const promises = []
              this.goodsInputListInfo.forEach(row => {
                const param = {
                  noSales: row.noSales,
                  cdItem: row.cdItem,
                  noWbl: row.noWbl,
                  검수수량: row.qtPack
                }
                const promise = this.$_sp.runUpdateSqlProc('패킹리스트', ['noSales', 'cdItem', 'noWbl'], null, [param])
                  .then((result) => {
                    row.검수수량 = row.qtPack
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                promises.push(promise)
              })

              Promise.all(promises)
                .then(() => {
                  this.$refs.refGoodsInputGrid.instance.refresh()
                  this.isLoadingSlidePage = false
                  this.checkAllInspection()
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          },
          {
            text: '취소',
            action: (toast) => { this.$snotify.remove(toast.id) }
          }
        ]
      })
    },
    checkAllInspection () {
      const isSumEqual = this.goodsInputListInfo.every(item => item.qtPack === item.검수수량)
      if (isSumEqual) {
        this.$snotify.info('전품목 검수 완료 하였습니다.')
      }
    },
    onRowUpdated ({ data }) {
      const param = {
        noSales: data.noSales,
        cdItem: data.cdItem,
        noWbl: data.noWbl,
        검수수량: data.검수수량
      }
      console.log(data)
      this.$_sp.runUpdateSqlProc('패킹리스트', ['noSales', 'cdItem', 'noWbl'], null, [param])
        .then((result) => {
          this.checkAllInspection()
        }).catch((err) => {
          console.log(err)
        }).finally((e) => {
          this.isLoadingSlidePage = false
        })
    },
    onRowUpdating (row) {
      if (row.newData.검수수량 > row.oldData.qtPack) {
        this.$snotify.error('요청수량보다 검수수량이 많습니다.')
        row.cancel = true
      }
    },
    onInputGridRowDblClick (e) {
      this.regInspection(e.data)
    }
  }
}
</script>

<style lang="scss">
  .left-draw-content {
    background-color: black;
  }
</style>
